
















































































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { useElementVisibility } from "@vueuse/core";
import { getCoachName } from "@/lib/getCoachName";
import { useMemberFilters } from "@/lib/hooks/useMemberFilters";
import { buttonVariants } from "@/lib/pfg/components/button";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { flattenMembers, infiniteQueryOptions_members } from "@/lib/query/functions/members";
import { getIconName } from "@/utils/images";

export default defineComponent({
	components: { Label },
	setup() {
		const loadMoreButton = ref<HTMLButtonElement | null>(null);
		const loadMoreButtonIsVisible = useElementVisibility(loadMoreButton);
		const { data: gyms } = useQuery(queryOptions_gyms());

		const { filters, reset: resetFilters } = useMemberFilters();

		const { data: coaches } = useQuery(
			queryOptions_coaches(
				computed(() => ({
					gymId: gyms.value?.find((gym) => gym.slug === filters.value.vestiging)?.id,
				})),
			),
		);

		const { status, data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery(
			infiniteQueryOptions_members(filters),
		);

		const members = computed(() => flattenMembers(data.value));

		watch(
			computed(() => ({
				status: status.value,
				hasNextPage: hasNextPage?.value,
				loadMoreButtonIsVisible: loadMoreButtonIsVisible.value,
			})),
			(value) => {
				if (value.status !== "success" || !value.hasNextPage || !value.loadMoreButtonIsVisible) {
					return;
				}

				fetchNextPage();
			},
			{ deep: true, immediate: true },
		);

		return {
			buttonVariants,
			loadMoreButton,
			filters,
			resetFilters,
			status,
			members,
			hasNextPage,
			isFetchingNextPage,
			fetchNextPage,
			getIconName,
			getFullName: getCoachName,
			gyms,
			coaches,
		};
	},
});

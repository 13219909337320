import { render, staticRenderFns } from "./ModalGroepslesAanmelden.vue?vue&type=template&id=066f270b&scoped=true"
import script from "./ModalGroepslesAanmelden.vue?vue&type=script&lang=ts"
export * from "./ModalGroepslesAanmelden.vue?vue&type=script&lang=ts"
import style0 from "./ModalGroepslesAanmelden.vue?vue&type=style&index=0&id=066f270b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066f270b",
  null
  
)

export default component.exports
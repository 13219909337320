import { QueryClient } from "@tanstack/vue-query";
import { AxiosError } from "axios";
import { minutesToMilliseconds } from "date-fns";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: minutesToMilliseconds(30),
			staleTime: minutesToMilliseconds(5),
			retry: (failureCount, error) => {
				const shouldRetry = failureCount < 3;

				if (!shouldRetry) {
					return shouldRetry;
				}

				if (error instanceof AxiosError) {
					return error.response?.status !== 403 && shouldRetry;
				}

				return shouldRetry;
			},
		},
	},
});

import axios from "@/axios";
import config from "@/config";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { DEFAULT_LOCATION } from "@/lib/constants/misc";
import { ClubApi } from "./club.api";
import { CoachApi } from "./coach.api";
import { type ApiConfig, ContentType, CoreApi } from "./core.api";
import { type OauthToken, ReserveerApi } from "./reserveer.api";

type SecurityData = OauthToken | undefined;

const sharedOptions: ApiConfig<SecurityData> = {
	secure: true,
	headers: { "Content-Type": ContentType.Json },
	securityWorker: (securityData) => ({
		headers: {
			// FUTURE: Possibly remove this in the future when the gym location is no longer needed within the headers
			"Gym-Location": localStorage.getItem(LS_LOCATION) ?? DEFAULT_LOCATION,
			...(!!securityData?.access_token && {
				Authorization: `${securityData.token_type ?? "Bearer"} ${securityData.access_token}`,
			}),
		},
	}),
};

export const coreApi = new CoreApi({
	baseURL: config.coreBackend,
	...sharedOptions,
});

export const reserveerApi = new ReserveerApi({
	baseURL: config.reserveer.server,
	...sharedOptions,
});

export const clubApi = new ClubApi({
	baseURL: config.club.server,
	...sharedOptions,
});

export const coachApi = new CoachApi({
	baseURL: config.coach.server.replace(/\/api$/, ""),
	...sharedOptions,
});

export function performForAllApis(
	fn: (api: typeof coreApi | typeof reserveerApi | typeof clubApi | typeof coachApi) => void,
) {
	return [coreApi, reserveerApi, clubApi, coachApi].forEach(fn);
}

export function performForAllApiInstances(
	fn: (
		api:
			| typeof axios
			| typeof coreApi.instance
			| typeof reserveerApi.instance
			| typeof clubApi.instance
			| typeof coachApi.instance,
	) => void,
) {
	return [axios, coreApi.instance, reserveerApi.instance, clubApi.instance, coachApi.instance].map(
		fn,
	);
}

export function setSecurityData(securityData: SecurityData | null | undefined) {
	performForAllApis((api) => api.setSecurityData(securityData));

	if (!securityData) {
		delete axios.defaults.headers.common.Authorization;
	} else {
		axios.defaults.headers.common.Authorization = `${securityData.token_type ?? "Bearer"} ${securityData.access_token}`;
	}
}

import { computed, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { performForAllApiInstances } from "@/lib/backend";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { DEFAULT_LOCATION, DEFAULT_LOCATION_ID } from "@/lib/constants/misc";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";
import { logger } from "@/logger";

export function useCurrentGym() {
	const { data: gyms } = useQuery(queryOptions_gyms());

	/** @deprecated Legacy, please use `gymId` as source of truth */
	const locationSlug = useStorage<string>(LS_LOCATION, DEFAULT_LOCATION, localStorage, {
		serializer: StorageSerializers.string,
		onError: logger.error,
	});

	const locationId = computed(() => gyms.value?.find((gym) => gym.slug == locationSlug.value)?.id);

	const gymId = useStorage<number>(
		"gymId",
		locationId.value ?? gyms.value?.[0]?.id ?? DEFAULT_LOCATION_ID,
		localStorage,
		{
			serializer: StorageSerializers.number,
			onError: logger.error,
		},
	);

	const gym = computed(() => gyms.value?.find((gym) => gym.id === gymId.value));
	const gymSlug = computed(() => gym.value?.slug);

	watch(
		[gyms, gym],
		([currentGyms, currentGym]) => {
			if (!currentGyms || currentGyms.length === 0 || currentGym) {
				return;
			}

			gymId.value = currentGyms[0].id;
		},
		{ immediate: true },
	);

	watch(
		gymSlug,
		(currentGymSlug) => {
			if (!currentGymSlug) {
				return;
			}

			localStorage.setItem(LS_LOCATION, currentGymSlug);

			performForAllApiInstances(
				(apiInstance) => (apiInstance.defaults.headers.common["Gym-Location"] = currentGymSlug),
			);
		},
		{ immediate: true },
	);

	return {
		/** @deprecated Please use dynamic `slug` instead */
		legacy_locationSlug: locationSlug,
		id: gymId,
		slug: gymSlug,
		gym,
	};
}


























































import { type PropType, defineComponent } from "@vue/composition-api";
import type { IomodelsCoachappAfgenomenMeting } from "@/lib/backend/club.api";

export default defineComponent({
	props: {
		data: {
			type: Array as PropType<IomodelsCoachappAfgenomenMeting[]>,
			default: (): IomodelsCoachappAfgenomenMeting[] => [],
		},
	},
	methods: {
		metingText(value: string | number | null | undefined) {
			if (!value) {
				return "-";
			}

			return value;
		},
	},
});
